import { v4 } from 'uuid';

import TYPES from '@/types';

// Application
import { CreateOnBoardingInitialContributionProofFileCommand }
  from '@/modules/onboarding/documents/application/commands';
import { CreateOnboardingStepCommand } from '@/modules/onboarding/status/application/commands';

// Domain
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';
import Functions from '@/modules/shared/domain/utils/functions';

export default class InitialContributionViewModel {
  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  @Inject(TYPES.UTIL_FUNCTIONS)
  private readonly functions!: Functions;

  @Inject(TYPES.CREATE_ON_BOARDING_INITIAL_CONTRIBUTION_PROOF_FILE_COMMAND)
  private readonly create_on_boarding_initial_contribution_proof_file_command!:
    CreateOnBoardingInitialContributionProofFileCommand;

  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnboardingStepCommand;

  private readonly view!: any;

  readonly inputs_config = {
    proof_of_initial_contribution: {
      size_limit: 1000 * 1024 * 4,
      accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    },
  };

  loading = false;

  inputs: Record<string, any> = {
    proof_of_initial_contribution: null,
  };

  get can_continue() {
    return this.inputs.proof_of_initial_contribution && !this.loading;
  }

  public constructor(view: any) {
    this.view = view;
  }

  selectFile = async ({ target }: any) => {
    const selected_file = target.files[0];
    const valid_file_size = (
      selected_file.size <= this.inputs_config.proof_of_initial_contribution.size_limit
    );

    if (valid_file_size) {
      this.inputs.proof_of_initial_contribution = selected_file;
    } else {
      this.notifier.showErrorNotification('El comprobante no puede pesar más de 4 Mb');
    }
  }

  continue = async () => {
    try {
      this.loading = true;
      const file_data = await this.functions.convert_file_to_base_64(
        this.inputs.proof_of_initial_contribution,
      );
      const create_file_dto = {
        id: v4(),
        name: this.inputs.proof_of_initial_contribution.name,
        mime_type: this.inputs.proof_of_initial_contribution.type,
        file_data: file_data as string,
      };

      await this.create_on_boarding_initial_contribution_proof_file_command.execute(
        create_file_dto,
      );
      await this.create_on_boarding_step_command.execute({
        id: v4(),
        current_step: 'hiring_process_completed',
        payload: {},
      });
      this.view.$emit('nextStep');
    } catch {
      this.notifier.showErrorNotification('Hubo un error al subir su comprobante de pago');
    } finally {
      this.loading = false;
    }
  };
}
