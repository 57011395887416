
















































































import { Vue, Component } from 'vue-property-decorator';

import InitialContributionViewModel
  from '@/vue-app/view-models/components/on-boarding/registration-status/initial-contribution-view-model';
import PreviewDocument from '@/vue-app/components/documents/preview-document.vue';

@Component({
  name: 'InitialContribution',
  components: { PreviewDocument },
})
export default class InitialContribution extends Vue {
  initial_contribution_view_model = Vue.observable(new InitialContributionViewModel(this));
}
